<template>
    <div class="pt-16 px-2 ">
        <h1 class="text-red text-lg mb-6">
            Edit Download
        </h1>
        <div class="" v-if="item">
            <FormTemplate @response="formResponse" button="Save" method="post" action="/download" :formdata="formData">
                <TextInput type="text" label="Name" v-model="item.name" :required="false" ></TextInput>
                <TextInput type="text" label="Download Path" v-model="item.download_path" :required="false" ></TextInput>
                <TextInput type="text" label="Version Number (0.0.0)" v-model="item.version" :required="false" ></TextInput>
                <SearchSelectInput @item:removed="productRemoved" @item:selected="productSelected" :params="productSearchParams" :multiple="false" label="Product" v-model="item.product" :optiondisplay="productDisplay"></SearchSelectInput>
            </FormTemplate>
        </div>
    </div>
</template>

<script>
import View from '../../../mixins/View'

export default {
    name: 'Download',
    mixins: [View],
    data: () => ({
        productSearchParams: {
            'model': 'Product',
            'fields': ['name'],
            'action': '/search/',
        },
        parameter: 'download_id',
        action: '/download/',
    }),
    computed: {
        formData() {
            return {
                'download':this.item,
            }
        },
    },
    props: [],
    methods: {
        productDisplay(item) {
            return item.name;
        },
        productSelected(evt) {
            this.item.product_id = evt.item.id
            this.item.product = evt.item
        },
        productRemoved() {
            this.item.product_id = 0
            this.item.product = false
        },
    },
    watch: {
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
